import AxiosService from "@/services/AxiosService";

class DashboardService {
  async getUser() {
    return await AxiosService.get(`/v1/users`)
      .then((response) => {
        if (response.data?.data?.ban_reason) {
          localStorage.setItem("ban_reason", response.data.data.ban_reason);
        } else {
          localStorage.setItem("ban_reason", "");
        }
        return response.data?.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async getData(data) {
    const day = data ? "today" : "yesterday";
    return await AxiosService.get(`/v1/dashboard/${day}`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new DashboardService();
